import React, { useState, useContext, useEffect } from 'react'
import qs from 'query-string'
import styled from 'styled-components'
import { fontSize } from 'styled-system'
import { Redirect } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input as _Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import * as actions from '../../../_actions'
import logo from '../../../assets/img/brand/WISEMD_LOGO_UC23.svg'
import ClerkshipSelector from '../ClerkshipSelector/ClerkshipSelector'
import { UserContext } from '../../../App'

const Input = styled(_Input)`
  ${fontSize}
`

const Login = ({ history, location }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { state, dispatch, api } = useContext(UserContext)

  useEffect(() => {
    ssoLoginHandler()
  }, [])

  async function ssoLoginHandler() {
    const { jwt, userName, isAdmin, course } = qs.parse(location.search)

    const ssoSession = { jwt, userName, isAdmin }
    const isSSO = jwt && userName
    isSSO && sessionStorage.setItem('session', JSON.stringify(ssoSession))
    isSSO && jwt && sessionStorage.setItem('jwt', jwt)

    if (isSSO) {
      console.log('AAA')
      const { data = {} } = await api.post(`/login/AQF`)
      console.log(data)
      console.log('BBB')

      if (course && course == 'test') {
        console.log('CCC')
        isSSO && history.push('/clerkship')
      } else {
        isSSO && history.push('/module/surgery')
      }
      console.log('DDD')
    }
  }

  // submit login form to express
  async function onSubmit(e) {
    try {
      e.preventDefault()
      const credentials = { username, password }
      const { data = {} } = await api.post(`/login`, credentials)

      sessionStorage.setItem('session', JSON.stringify(data))
      sessionStorage.setItem('jwt', data.jwt)
      dispatch({
        type: actions.USER_UPDATE,
        payload: { ...data, hasError: false, error: null },
      })

      const { jwt } = data
      const session = JSON.stringify(data)
      sessionStorage.setItem('session', session)
      sessionStorage.setItem('jwt', jwt)
    } catch (err) {
      console.log(err)
      var msg =
        err.response && err.response.data && err.response.data.msg
          ? err.response.data.msg
          : err.message
      dispatch({
        type: actions.USER_UPDATE,
        payload: {
          hasError: true,
          error: err.message,
          jwt: '',
          statusMsg: msg,
        },
      })
      throw new Error('Error with Login', err)
    }
  }

  return (
    <Form
      className="app flex-column justify-content-center align-items-center"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Container>
        {state.jwt && !!state.passwordReset ? (
          //  REDIRECT IF PASSWORD RESET ENABLED
          <Redirect to="/passwordReset" />
        ) : // PROMPT FOR CLERKSHIP IF NONE SELECTED
        state.jwt && !state.isAdmin && !state.clerkshipID ? (
          <ClerkshipSelector clerkships={state.clerkships} />
        ) : // REDIRECT TO MODULES UPON SUCCESSFUL LOGIN
        state.jwt && (state.clerkshipID || state.isAdmin) ? (
          <Redirect to="/module/" />
        ) : null}
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <img src={logo} alt="logo" width="144px" className="mb-3" />
                  <p className="text-muted">Sign into your account</p>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoFocus
                      fontSize={2}
                      type="text"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      fontSize={2}
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                  <Row>
                    <div
                      className={
                        'w-100 p-2 mb-3 font-weight-bold text-white ' +
                        (state.statusMsg ? 'bg-primary' : '') +
                        ' rounded'
                      }
                      style={{ fontSize: 1.05 + 'rem' }}
                    >
                      {state.statusMsg ? state.statusMsg : ''}
                    </div>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Button
                        type="submit"
                        color="primary"
                        className="px-4"
                        onClick={(e) => onSubmit(e)}
                      >
                        Login
                      </Button>
                    </Col>
                    {/* <Col xs="6" className="text-right"> */}
                    {/* <Button color="link" className="px-0">
                        Forgot password?
                      </Button> */}
                    {/* </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}

export default Login
