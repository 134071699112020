import React, { useState, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import { UserContext } from '../../../App'
const courses = {
  wmd: {
    name: 'Surgery + Skills',
  },
  woc: {
    name: 'OnCall',
  },
  hbf: {
    name: 'CARE',
  },
}

export default function CourseClerkshipSelector({ clerkships, course }) {
  let [hasClerkship, setHasClerkship] = useState(false)
  let { api } = useContext(UserContext)
  let clerkshipList = clerkships.reduce((accumulator, clerkship) => {
    if (clerkship && clerkship.course == course) {
      accumulator.push(clerkship)
    }
    return accumulator
  }, [])

  let courseName = course && courses[course] ? courses[course].name : ''

  return hasClerkship ? (
    <Redirect to="/module/surgery" />
  ) : (
    <Modal isOpen={true} centered={true} size={'lg'}>
      <ModalHeader className="bg-primary">
        Select a Clerkship ({courseName})
      </ModalHeader>
      <ModalBody>
        <ListGroup>
          {clerkshipList.map((clerkship, index) => (
            <ListGroupItem key={index}>
              <Link
                to="#"
                onClick={() => {
                  const { clerkshipID } = clerkship
                  const config = { clerkshipID }
                  api
                    .post('/addUserToClerkship', config)
                    .then((res) => {
                      res.data.status === 1
                        ? setHasClerkship(true)
                        : setHasClerkship(false)
                    })
                    .catch((err) => console.log(err))
                }}
              >
                {clerkship.clerkshipName}
              </Link>
            </ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>
    </Modal>
  )
}
