import React from 'react'
/**@jsx jsx */

import { jsx, ThemeProvider } from 'theme-ui'
import { Heading, Box, Card, Flex } from '@theme-ui/components'
import theme from '../../theme.js'

function ThemedCard({ heading, children }) {
  return (
    <ThemeProvider theme={theme}>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Card className="shadow-sm bg-white">
          <Flex className="card-header sticky">
            <Heading>{heading}</Heading>
          </Flex>
          <Box sx={{ p: 3 }}>{children}</Box>
        </Card>
      </Flex>
    </ThemeProvider>
  )
}

export default ThemedCard
