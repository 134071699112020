import React from 'react'
import { queryCache, useQuery, useMutation } from 'react-query'
import { UserContext } from '../App'
import { format, addYears } from 'date-fns'

export function useClerkship(dispatch, state) {
  const { api, env } = React.useContext(UserContext)
  const formatDate = (date) => format(date, 'YYYY/MM/DD')

  const institution = {
    domainID: state.currentInstitution?.domainID,
  }

  const handleAddNewClerkship = React.useCallback(async () => {
    const today = new Date()
    dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: true })
    dispatch({ type: 'SET_CLERKSHIP', payload: {} })
    dispatch({
      type: 'SET_CLERKSHIP_DATA',
      payload: {
        startDate: formatDate(today),
        endDate: formatDate(addYears(today, 1)),
      },
    })
  }, [])

  const handleEditClerkship = React.useCallback(async (clerkship) => {
    dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: false })
    dispatch({
      type: 'SET_CLERKSHIP',
      payload: {
        ...clerkship,
        startDate: formatDate(clerkship.startDate),
        endDate: formatDate(clerkship.endDate),
      },
    })
  }, [])

  const handleCancelClerkship = React.useCallback(async () => {
    dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: false })
    dispatch({ type: 'SET_CLERKSHIP', payload: {} })
  }, [])

  const handleClerkshipData = React.useCallback(async ({ field, value }) => {
    dispatch({ type: 'SET_CLERKSHIP_DATA', payload: { [field]: value } })
  }, [])

  const query = useQuery(
    ['clerkships', institution.domainID],
    async () => {
      const config = { params: { did: institution.domainID } }
      const url = `${env.API_ENDPOINT_RPT}/clerkshipList`
      const { data } = await api.get(url, config)
      return data
    },
    {
      enabled: institution.domainID,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  const [create, createInfo] = useMutation(
    async () => {
      const url = `${env.API_ENDPOINT_GW}/admin/addClerkships`
      const payload = {
        domainID: state.currentInstitution?.domainID,
        clerkships: [
          {
            name: state.currentClerkship?.clerkshipName,
            clerkshipID: state.currentClerkship?.clerkshipID,
            isDefault: state.currentClerkship?.isDefault,
            course: state.currentClerkship?.course,
            startDate: state.currentClerkship?.startDate,
            endDate: state.currentClerkship?.endDate,
          },
        ],
      }
      const { data } = await api.post(url, payload)
      return data
    },
    {
      onSettled: (data) => {
        console.log({ data })
        queryCache.invalidateQueries(['clerkships', institution.domainID])
      },
    }
  )

  const [update, updateInfo] = useMutation(
    async () => {
      const url = `${env.API_ENDPOINT_GW}/admin/addClerkships`
      const payload = {
        domainID: state.currentInstitution?.domainID,
        clerkships: [
          {
            name: state.currentClerkship?.clerkshipName,
            clerkshipID: state.currentClerkship?.clerkshipID,
            isDefault: state.currentClerkship?.isDefault,
            course: state.currentClerkship?.course,
            startDate: state.currentClerkship?.startDate,
            endDate: state.currentClerkship?.endDate,
          },
        ],
      }
      const { data } = await api.put(url, payload)
      return data
    },
    {
      onSettled: (data) => {
        console.log({ data, institution })
        dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: false })
        queryCache.invalidateQueries(['clerkships', institution.domainID])
      },
    }
  )

  const columns = React.useMemo(() => {
    return [
      { Header: 'Name', accessor: 'clerkshipName' },
      { Header: 'ID', accessor: 'clerkshipID' },
      { Header: 'Course', accessor: 'course' },
      { Header: 'Default?', accessor: 'isDefault' },
      { Header: 'Start', accessor: 'startDate' },
      { Header: 'End', accessor: 'endDate' },
      {
        Header: 'Modify',
        id: 'modify',
        Cell: ({ row }) => (
          <a
            href="#"
            title="Edit Clerkship"
            onClick={(e) => {
              e.preventDefault()
              handleEditClerkship(row.values)
              dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: false })
            }}
          >
            Edit
          </a>
        ),
      },
    ]
  }, [])
  return {
    query,
    columns,
    handleAddNewClerkship,
    handleEditClerkship,
    handleCancelClerkship,
    handleClerkshipData,
    create,
    createInfo,
    update,
    updateInfo,
  }
}
