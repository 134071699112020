require('dotenv-flow').config()

export const filters = ['Completed', 'In Progress', 'Not Started', 'All']
export const views = ['Grid', 'List']
export const moduleViews = ['Current', 'Retired']
export const moduleViewFilters = {
  Current: [2, 1], // -2],
  Latest: [2, 1],
  // Retiring: [-2],
  // Replaced: [-2, -3],
  Removed: [-1],
  Retired: [0],
}

export const moduleViewNames = {
  // '-3': 'Replaced',
  // '-2': 'Replaced',
  2: 'Revised',
  0: 'Retired',
}

export const moduleActiveStates = {
  2: 'Revised',
  1: 'Active',
  // '-2': 'Replaced',
  '-1': 'Removed',
  0: 'Retired',
}

export const moduleActiveStatesByName = {
  Revised: 2,
  Active: 1,
  // Replaced: '-2',
  Removed: '-1',
  Retired: 0,
}

export const sectionInstrux = {
  CurrentX: '',
  ReplacedX: 'Replaced description goes here',
  RetiredX: ` These modules are being retired. They will continue to be accessible and reported for 60 days after the retirement date. If you wish to have a report of this activity for your records, please dwnload it before that date. Please confirm with your instructor which version you should complete `,
  Retired: `These modules are now retired. You can continue to access the modules and download reporting for 60 days after the retirement date. After that date, they will no longer be available for viewing or reporting. If unsure about which version to complete, please confirm with your instructor.`,
}
export const reportInstrux = {
  CurrentGeneral: `Use the <b>Current/Retired</b> filter above to access reporting for Retired modules. Reporting for Retired modules is available for 60 days after the retirement date.  `,
  RetiredGeneral: `These modules are fully retired. Reporting for Retired modules is available for 60 days after the retirement date.  `,
}

export const moduleFilterSets = Object.keys(moduleViewFilters).reduce(
  (arr, k, i) => {
    moduleViewFilters[k].map((oo, ii) => {
      if (!arr[oo]) {
        arr[oo] = []
      }
      arr[oo].push(k)
    })
    return arr
  },
  {}
)

export const API_ENDPOINT = process.env.API_ENDPOINT
  ? 'http://' + process.env.API_ENDPOINT
  : 'http://api.wise-md.group'
