import React from 'react'
import { Icon } from '@mdi/react'
import { withRouter } from 'react-router'
import { Flex, Box, Text } from 'rebass'
import { mdiCloseCircle } from '@mdi/js'
import { Modal, ModalBody, Button } from 'reactstrap'
import { useAuthCheck, useOncallModule, useExtendJwt } from './hooks'

const AuthCheck = ({ location, history }) => {
  const [isJwtExpired, setIsJwtExpired] = useAuthCheck(process.env.AUTH_CHECK_INTERVAL_LMS || 60000)
  const [isOncallModule] = useOncallModule(location, setIsJwtExpired)
  useExtendJwt(history, isOncallModule, isJwtExpired)

  const isExpired = isOncallModule
    ? isOncallModule && isJwtExpired
    : isJwtExpired

  return (
    <>
      <Modal isOpen={isExpired}>
        <ModalBody>
          <Flex my={4}>
            <Box ml={4}>
              <Icon path={mdiCloseCircle} size={4} color={'var(--danger)'} />
            </Box>
            <Box mx={3} width={1}>
              <Box my={2}>
                <Text fontSize={4}>Your session has expired.</Text>
              </Box>
              <Box my={1}>
                <Text fontSize={3} className="text-muted">
                  Please login, again.
                </Text>
              </Box>
            </Box>
          </Flex>
          <Flex justifyContent="center" mx={4} my={4}>
            <Button
              block
              size="lg"
              className="shadow-sm"
              onClick={() => {
                history.replace("/session_expired")               
              }}
            >
              <Text fontSize={2} fontWeight={700}>
                Back to Login
              </Text>
            </Button>
          </Flex>
        </ModalBody>
      </Modal>
    </>
  )
}

export default withRouter(AuthCheck)
