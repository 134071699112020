import axios from 'axios'

export function useAxios({ state, jwt, API_ENDPOINT_RPT }) {
  const api = axios.create()

  api.defaults.baseURL = API_ENDPOINT_RPT
  api.defaults.headers.common['X-Authorization'] = state.jwt || jwt

  // when browsing and logged in,
  // have axios use latest jwt found in sessionStorage
  api.interceptors.request.use(
    (config) => {
      const jwt = sessionStorage.getItem('jwt')
      config.headers['X-Authorization'] = jwt
      return config
    },
    (err) => Promise.reject(err)
  )
  return { api }
}
