export const prodList = {
  wmd3: {
    admin: false,
    name: 'Surgery',
    url: '/module/surgery',
    icon: 'fa fa-user-md',
  },
  woc: {
    admin: false,
    name: 'OnCall',
    url: '/module/oncall',
    icon: 'fa fa-ambulance fa-flip-horizontal',
  },
  skills: {
    admin: false,
    name: 'Skills',
    url: '/module/skills',
    icon: 'fa fa-stethoscope',
    class: ['prod', 'skills'],
  },
  hbf: {
    admin: false,
    name: 'CARE',
    url: '/module/addiction',
    icon: 'fa fa-medkit',
    class: ['prod', 'hbf'],
  },
}

export const modulesNavHeader = [
  {
    admin: false,
    title: true,
    name: 'Modules',
    url: '',
    wrapper: {
      // optional wrapper object
      element: '', // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    },
    class: '', // optional class names space delimited list for title item ex: "text-center"
  },
]

export const reportsNav = [
  {
    admin: false,
    title: true,
    url: '',
    name: 'Reports',
    wrapper: {
      element: '',
      attributes: {},
    },
  },

  {
    admin: false,
    name: 'Module Progress',
    url: '/reports/modules',
    icon: 'fa fa-table',
  },
  // {
  //   admin: false,
  //   name: 'Question Progress',
  //   url: '/reports/questions',
  //   icon: 'fa fa-table',
  // },
  {
    admin: false,
    name: 'Quiz Activity',
    url: '/reports/questions2',
    icon: 'fa fa-table',
  },
]

export const studentNav = [
  {
    admin: false,
    title: true,
    name: 'Modules',
    url: '',
    wrapper: {
      // optional wrapper object
      element: '', // required valid HTML5 element tag
      attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    },
    class: '', // optional class names space delimited list for title item ex: "text-center"
  },
  {
    admin: false,
    title: true,
    url: '',
    name: 'Reports',
    wrapper: {
      element: '',
      attributes: {},
    },
  },

  {
    admin: false,
    name: 'Module Progress',
    url: '/reports/modules',
    icon: 'fa fa-table',
  },
  // {
  //   admin: false,
  //   name: 'Question Progress',
  //   url: '/reports/questions',
  //   icon: 'fa fa-table',
  // },
  {
    admin: false,
    name: 'Quiz Activity',
    url: '/reports/questions2',
    icon: 'fa fa-table',
  },
  {
    admin: false,
    name: 'Surgery',
    url: '/module/surgery',
    icon: 'fa fa-user-md',
  },
  {
    admin: false,
    name: 'OnCall',
    url: '/module/oncall',
    icon: 'fa fa-ambulance fa-flip-horizontal',
  },
  {
    admin: false,
    name: 'Skills',
    url: '/module/skills',
    icon: 'fa fa-heartbeat',
  },
  {
    admin: false,
    name: 'Addiction',
    url: '/module/addiction',
    icon: 'fa fa-heartbeat',
  },
]

export const adminNav = [
  {
    admin: true,
    title: true,
    name: 'Admin',
    url: '',
    wrapper: {
      element: '',
      attributes: {},
    },
  },

  {
    admin: true,
    name: 'User List',
    url: '/admin/userlist',
    icon: 'fa fa-table',
  },
  {
    admin: true,
    name: 'User Progress',
    url: '/admin/userprogress',
    icon: 'fa fa-table',
  },
  {
    admin: true,
    name: 'User Activity',
    url: '/admin/useractivity',
    icon: 'fa fa-table',
  },
]

export const superNav = [
  {
    superAdmin: true,
    title: true,
    name: 'Super Admin',
    url: '',
    wrapper: {
      element: '',
      attributes: {},
    },
  },
  {
    superAdmin: true,
    name: 'Super Admin',
    url: '/admin/superadmin',
    icon: 'fa fa-hospital-o',
  },
  {
    superAdmin: true,
    name: 'Bulk Add Users',
    url: '/admin/addstudents',
    icon: 'fa fa-hospital-o',
  },
  {
    superAdmin: true,
    name: 'Clone User',
    url: '/admin/cloneuser',
    icon: 'fa fa-hospital-o',
  },
]

export const logoutNav = [
  { divider: true },
  {
    name: 'Logout',
    url: '/logout',
    icon: 'icon-logout',
    class: 'mt-auto',
    variant: 'danger',
  },
]
