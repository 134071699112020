import React from 'react'
import Icon from '@mdi/react'
import { Input } from 'reactstrap'
import { Flex, Box, Heading, Text } from 'rebass'

export const HeaderIcon = props => (
  <Flex flexDirection="row" alignItems="center" mb={2}>
    <Box mx={1}>
      <Icon path={props.icon} size={props.iconSize} />
    </Box>
    <Box>
      <Heading fontSize={[2, 3]} {...props}>
        {props.children}
      </Heading>
    </Box>
  </Flex>
)

export const UserInput = ({ children, input, dispatch }) => {
  return (
    <Box width={[1, 1 / 3]} pr={4}>
      <Text m={1} fontSize={['.9em', 1]}>
        {children}
      </Text>
      <Input
        type="search"
        onChange={e => {
          dispatch({
            type: `UPDATE_FORM`,
            showUserList: false,
            payload: { [input]: e.target.value },
          })
        }}
      />
    </Box>
  )
}

export const FormStatus = ({ message }) => (
  <Flex justifyContent="center">
    <Box my={2} width={1}>
      <Heading className="text-muted text-center">{message}</Heading>
    </Box>
  </Flex>
)
