import React from 'react'
import Loadable from 'react-loadable'
import DefaultLayout from './containers/DefaultLayout/DefaultLayout'

function Loading() {
  return <div>Loading...</div>
}

const ModuleCollection = Loadable({
  loader: () =>
    import('./views/ModuleCollection/ModuleCollection/ModuleCollection'),
  loading: Loading,
})

const QuestionProgress = Loadable({
  loader: () => import('./views/Reports/QuestionProgress/QuestionProgress'),
  loading: Loading,
})
const QuestionProgress2 = Loadable({
  loader: () => import('./views/Reports/QuestionProgress/QuestionProgress2'),
  loading: Loading,
})

const ModuleProgress = Loadable({
  loader: () => import('./views/Reports/ModuleProgress/ModuleProgress'),
  loading: Loading,
})

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
})

const AdminUserList = Loadable({
  loader: () => import('./views/Reports/Admin/UserList/UserList'),
  loading: Loading,
})

const AdminUserProgress = Loadable({
  loader: () => import('./views/Reports/Admin/UserProgress/UserProgress'),
  loading: Loading,
})

const AdminUserActivity = Loadable({
  loader: () => import('./views/Reports/Admin/UserActivity/UserActivity'),
  loading: Loading,
})

const SuperAdminAddStudents = Loadable({
  loader: () => import('./views/SuperAdmin/AddStudents/AddStudents/'),
  loading: Loading,
})

const SuperAdminAddDomain = Loadable({
  loader: () => import('./views/SuperAdmin/AddDomain/AddDomain/'),
  loading: Loading,
})
const SuperAdminAddClerkship = Loadable({
  loader: () => import('./views/SuperAdmin/AddClerkship/AddClerkship/'),
  loading: Loading,
})

const SuperAdminAddInstitution = Loadable({
  loader: () => import('./views/SuperAdmin/AddInstitution/AddInstitution/'),
  loading: Loading,
})

const SuperAdminCloneUser = Loadable({
  loader: () => import('./views/SuperAdmin/CloneUser/CloneUser/'),
  loading: Loading,
})

const Logout = Loadable({
  loader: () => import('./components/Logout/Logout'),
  loading: Loading,
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/logout', name: 'Logout', component: Logout },
  {
    path: '/module/:collection',
    exact: true,
    name: 'Course',
    component: ModuleCollection,
  },
  {
    path: '/reports/modules',
    exact: true,
    name: 'Reports',
    component: ModuleProgress,
  },
  {
    path: '/reports/questions',
    exact: true,
    name: 'Reports',
    component: QuestionProgress,
  },
  {
    path: '/reports/questions2',
    exact: true,
    name: 'Reports',
    component: QuestionProgress2,
  },
  {
    path: '/admin/userlist',
    exact: true,
    name: 'User List',
    component: AdminUserList,
  },
  {
    path: '/admin/userprogress',
    exact: true,
    name: 'User Progress',
    component: AdminUserProgress,
  },
  {
    path: '/admin/useractivity',
    exact: true,
    name: 'User Activity',
    component: AdminUserActivity,
  },
  {
    path: '/admin/addstudents',
    exact: true,
    name: 'Add Students',
    component: SuperAdminAddStudents,
  },
  {
    path: '/admin/addinstitution',
    exact: true,
    name: 'Add Institution',
    component: SuperAdminAddDomain,
  },
  {
    path: '/admin/superadmin',
    exact: true,
    name: 'Super Admin',
    component: SuperAdminAddInstitution,
  },
  {
    path: '/admin/addclerkship',
    exact: true,
    name: 'Add Clerkship',
    component: SuperAdminAddClerkship,
  },
  {
    path: '/admin/cloneuser',
    exact: true,
    name: 'Clone User',
    component: SuperAdminCloneUser,
  },
]

export default routes
