import { useState, useContext, useEffect } from 'react'
import { useInterval } from '../../../hooks/useInterval'
import { UserContext } from '../../../App'
import qs from 'query-string'

export function useAuthCheck(inMilliseconds) {
  const { api, env } = useContext(UserContext)
  const [isJwtExpired, setIsJwtExpired] = useState(false)
  const { API_ENDPOINT_RPT } = env

  // heartbeat to check if JWT is still valid
  useInterval(() => {
    // only run the check if the JWT is valid, otherwise skip unnecessary check
    if (!isJwtExpired) {
      api.get(`${API_ENDPOINT_RPT}/authCheck`).then(({ data }) => {
        // if rpt says jwt is expired, then show Modal and clear sessionStorage
        const isExpired = data.isJwtExpired
        if (isExpired) {
          setIsJwtExpired(data.isJwtExpired)
          sessionStorage.clear()
        }
      })
    }
  }, inMilliseconds)
  return [isJwtExpired, setIsJwtExpired]
}

export function useOncallModule(location, setIsJwtExpired) {
  // set to true initially to allow qs to be parsed.
  const [isOncallModule, setIsOncallModule] = useState(true)

  useEffect(() => {
    const { search } = location
    const searchParams = qs.parse(search)
    const isOncall = !!searchParams.t
    const gracePeriod = 3600000 // 3 hours
    const currentTime = Date.now()
    const isOncallExpired =
      isOncall && gracePeriod < currentTime - Number(searchParams.t)

    isOncall ? setIsOncallModule(true) : setIsOncallModule(false)
    isOncallExpired && setIsJwtExpired(true)
    isOncall && !isOncallExpired
  }, [])

  return [isOncallModule]
}

export function useExtendJwt(history, isOncallModule, isJwtExpired) {
  const { api, env } = useContext(UserContext)
  const { API_ENDPOINT_RPT } = env

  async function extendJwt() {
    const { data } = await api.get(`${API_ENDPOINT_RPT}/extendJWT`)
    sessionStorage.setItem('jwt', data.jwt)
  }

  function extendJwtOnRouteChange() {
    var j = Date.now()

    var unlisten = history.listen(({ pathname }) => {
      const isLoggingOut =
        pathname.includes('login') ||
        pathname.includes('logout') ||
        pathname.includes('session_expired') ||
        (!isLoggingOut && !isJwtExpired && extendJwt())
    })
    return unlisten
  }

  useEffect(() => {
    if (!isJwtExpired) {
      var unlisten = extendJwtOnRouteChange()
      return () => {
        unlisten()
      }
    } else {
      return () => {}
    }
  }, [])

  useEffect(() => {
    isOncallModule && !isJwtExpired && extendJwt()
  }, [])
}
