import React from 'react'
import { useSpring, animated, config } from 'react-spring'
import { Box } from 'rebass'

interface ProgressBarProps {
  sectionDone: number
  sectionCount: number
}

export function ProgressBar(props: ProgressBarProps) {
  const progress = (props.sectionDone / props.sectionCount) * 100
  const progressColor = progress === 100 ? 'bg-success' : 'bg-primary'
  const { progressBar } = useSpring({
    progressBar: progress,
    from: { progressBar: 0 },
    config: config.default,
  })

  return (
    <Box width={1} mt={2}>
      <animated.div
        className={'bg-secondary'}
        style={{
          height: '.5em',
          width: '100%',
          borderRadius: '1em',
          zIndex: 0,
        }}
      >
        <animated.div
          className={progressColor}
          style={{
            height: '.5em',
            borderRadius: '1em',
            zIndex: 1,
            width: progressBar.interpolate(p => `${p}%`),
          }}
        />
      </animated.div>
    </Box>
  )
}
