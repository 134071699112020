import React, { useState } from 'react'
import { Text } from 'rebass'
import { useSpring, animated } from 'react-spring'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

export default function ButtonDropdown({ items, setFilter, filter }) {
  let [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(!dropdownOpen)
  const animation = useSpring({ opacity: 1, from: { opacity: 0 } })
  const AnimatedDropdownMenu = animated(DropdownMenu)

  return (
    <Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle} css={``}>
      <DropdownToggle caret size="sm">
        <Text fontSize={[0, 1]} className={'d-inline'}>
          {filter}
        </Text>
      </DropdownToggle>
      <AnimatedDropdownMenu style={animation} right>
        {/* <DropdownMenu> */}
        {items.map((item, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={e => setFilter(e.target.value)}
              value={item}
              active={item === filter}
            >
              {item}
            </DropdownItem>
          )
        })}
        {/* </DropdownMenu> */}
      </AnimatedDropdownMenu>
    </Dropdown>
  )
}
