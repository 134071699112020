import React from 'react'
import { jsx, Heading, Flex, Box } from 'theme-ui'
/**@jsx jsx */
import { Button } from 'reactstrap'

export function Header({ isActive, children, step = null }) {
  return (
    <Flex justifyContent="center" alignItems="center">
      {/* <span>
        <i className="icon-lock" />
      </span> */}
      <Heading
        className={`ttu fw1 black-80 text-primary`}
        //   className={`ttu fw1 black-80 ${isActive ? 'text' : 'text-muted'}`}
        sx={{ fontSize: [3, 4], my: [2, 3] }}
        // sx={{ my: [2, 3] }}
      >
        {children}
      </Heading>
    </Flex>
  )
}

export function SubHeader({ isActive, children }) {
  return (
    <Heading
      className={`f6 fw5 text-muted`}
      // sx={{ fontSize: [1, 2], my: [1, 2, 3] }}
      sx={{ my: [1, 2, 3] }}
    >
      {children}
    </Heading>
  )
}

export function Section({ header, subheader, children, ...props }) {
  return (
    <Box sx={{ my: 3 }}>
      <Header {...props}>{header}</Header>
      {subheader && <SubHeader {...props}>{subheader}</SubHeader>}
      {children}
    </Box>
  )
}

export function UpdateCard({ children }) {
  return (
    <Box
      sx={{ my: 3, p: 3, borderRadius: 4 }}
      className="shadow-sm border animated fadeIn"
    >
      {children}
    </Box>
  )
}

export function SubmitButton({
  children,
  onClick,
  isSuccess,
  isLoading,
  isError,
  isDisabled,
}) {
  return (
    <Button
      color={isSuccess ? 'success' : isError ? 'danger' : 'primary'}
      size="sm"
      sx={{ mr: 2 }}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isSuccess
        ? 'Success!'
        : isError
        ? 'Error!'
        : isLoading
        ? 'Loading...'
        : children}
    </Button>
  )
}
