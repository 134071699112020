import React, { useState, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import { UserContext } from '../../../App'

export default function ClerkshipSelector({ clerkships }) {
  let [hasClerkship, setHasClerkship] = useState(false)
  let { api } = useContext(UserContext)

  return hasClerkship ? (
    <Redirect to="/module/surgery" />
  ) : (
    <Modal isOpen={true} centered={true} size={'lg'}>
      <ModalHeader className="bg-primary">Select a Clerkship</ModalHeader>
      <ModalBody>
        <ListGroup>
          {clerkships.map((clerkship, index) => (
            <ListGroupItem key={index}>
              <Link
                to="#"
                onClick={() => {
                  const { clerkshipID } = clerkship
                  const config = { clerkshipID }
                  api
                    .post('/userClerkship', config)
                    .then(res => {
                      res.data.status === 1
                        ? setHasClerkship(true)
                        : setHasClerkship(false)
                    })
                    .catch(err => console.log(err))
                }}
              >
                {clerkship.clerkshipName}
              </Link>
            </ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>
    </Modal>
  )
}
