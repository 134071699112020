require('dotenv')
import * as actions from './_actions'
import * as nav from './_nav'

export const initialState = {
  clerkshipID: null,
  clerkshipPrompt: false,
  clerkships: [],
  domainID: null,
  domainName: null,
  error: null,
  groupID: null,
  hasError: false,
  isAuthenticated: false,
  isAdmin: false,
  isSuperAdmin: false,
  isStudent: false,
  jwt: null,
  lmsData: {},
  passwordReset: false,
  status: null,
  statusMsg: null, // Application Status Message
  ssoState: null,
  appState: null,
  sessionStoreUpdate: null,
  userName: null,
  prodList: [],
  loginState: null,
}

export const reducer = (state, action) => {
  let { type, payload } = action
  switch (type) {
    case actions.USER_UPDATE:
      return { ...state, ...payload }

    case actions.USER_MODULES:
      let prodList = []

      if (payload.wmd3 && payload.wmd3.length > 0) {
        prodList.push(nav.prodList.wmd3)
      }
      if (payload.skills && payload.skills.length > 0) {
        prodList.push(nav.prodList.skills)
      }
      if (payload.woc && payload.woc.length > 0) {
        prodList.push(nav.prodList.woc)
      }
      if (payload.hbf && payload.hbf.length > 0) {
        prodList.push(nav.prodList.hbf)
      }

      return { ...state, prodList: [...prodList], lmsData: { ...payload } }

    case actions.USER_LOGOUT:
      sessionStorage.clear()
      return { ...initialState, ...{ appState: 'logout' } }

    case actions.USER_CLEARALL:
      sessionStorage.clear()
      return { ...initialState }

    case actions.USER_UPD_SESS_STORE:
      var currentState = { ...state }
      var n = Date.now()
      sessionStorage.setItem('sessionUpdTime', n)
      currentState.sessionStoreUpdateTime = n
      if (payload && isObject(payload)) {
        Object.keys(payload).map((v, i) => {
          sessionStorage.setItem(v, payload[v])
        })
      }
      return currentState

    default:
      return { ...state }
  }
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}
