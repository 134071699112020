import React, { useState, useContext } from 'react'
import { UserContext } from '../../../../App'
import passwordValidator from 'password-validator'

export const usePwReset = () => {
  const [PW1, setPW1] = useState('')
  const [PW2, setPW2] = useState('')
  const [error, setError] = useState(null)
  const { dispatch, state, api } = useContext(UserContext)
  const doPasswordsMatch = PW1 === PW2
  const isPasswordBlank = PW1 === '' || PW2 === ''
  const pwSchema = new passwordValidator()
    .is()
    .min(8)
    .has()
    .not()
    .spaces()

  const isPasswordValid = pwSchema.validate(PW1)

  const handlePWReset = () => {
    if (isPasswordBlank) {
      setError('Password cannot be blank')
    } else if (!doPasswordsMatch) {
      setError('Passwords do not match')
    } else if (!isPasswordValid) {
      setError('Must be 8 characters')
    } else {
      try {
        api.post(`/passwordReset`, { password: PW1 }).then(data => {
          dispatch({ type: 'USER_UPDATE', payload: { passwordReset: false } })
          console.log(data)
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  return { PW1, PW2, state, handlePWReset, error, setPW1, setPW2 }
}
