import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Flex, Box } from 'rebass'

// CoreUI
import routes from '../../routes'
import DefaultFooter from './DefaultFooter'
import DefaultHeader from './DefaultHeader'
import { AppHeader, AppSidebar, AppSidebarNav } from '@coreui/react'

// Hooks
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { useNav, useFetchModuleList, useParseJwtInQueryString } from './hooks'
import { UserContext } from '../../App'

// Custom Components
import AuthCheck from '../../components/AuthCheck/AuthCheck'
import Modal from '../../components/Modal/Modal'

const DefaultLayout = (props) => {
  const { state } = useContext(UserContext)
  const { lmsData, imgbase, prodList, hasError, error } = state
  const { userName, isAdmin, jwt, groupID } = useSessionStorage()
  const { navigation } = useNav(isAdmin, groupID)
  useFetchModuleList(jwt)
  useParseJwtInQueryString(props)

  const isModuleRoute = props.location.pathname.includes('/module/')
  const isCollectionRoute = props.match.params && props.match.params.collection
  const hasProducts = prodList && Boolean(prodList.length)

  const hasSessionStorage = sessionStorage.length > 0
  const isLoggingOut = props.location.pathname.includes('/logout')
  const isSessionExpired = props.location.pathname.includes('/session_expired')

  const message =
    error && error.data && error.data.message ? error.data.message : null

  useEffect(() => {
    function logoutCleanup(msg = null) {
      sessionStorage.clear()
      clearState(state)
      state.statusMsg = msg || 'Logged out'
      props.history.replace('/login')

      function clearState(state) {
        if (isObject(state))
          Object.keys(state).forEach((o, i) => delete state[o])
      }
    }

    if (isLoggingOut) logoutCleanup('Logged out')
    else if (isSessionExpired) logoutCleanup('Session expired')
  }, [props.location, isLoggingOut])

  return hasSessionStorage ? (
    <div className="app">
      <AuthCheck />
      <AppHeader fixed>
        <DefaultHeader userName={userName} isAdmin={isAdmin} />
      </AppHeader>
      <div
        className="app-body "
        style={{ overflowX: 'visible', backgroundColor: 'var(--light)' }}
      >
        <AppSidebar fixed display="xl">
          <AppSidebarNav {...props} navConfig={navigation} />
        </AppSidebar>
        <Flex className="main" flexDirection={'column'}>
          {hasError && message && <Modal title={message} />}
          {isModuleRoute ? (
            hasProducts ? (
              isCollectionRoute ? (
                // show module:collection route
                <AppMainContent />
              ) : (
                // default to first in list as has product & no collection route
                <Redirect to={prodList[0].url} />
              )
            ) : // 'No courses assigned to clerkship'
            null
          ) : (
            // all other pages (i.e. no module page)
            <AppMainContent />
          )}
          <Box flex={'0 0 0'}>
            <DefaultFooter />
          </Box>
        </Flex>
      </div>
    </div>
  ) : null

  function AppMainContent() {
    return (
      <Box my={[0, 2, 3]} mx={[1, 2, 3]} flex={'1 0 0'}>
        {!isLoggingOut ? (
          <Switch>
            <ScrollToTop>
              {routes.map((route) => {
                return route.component ? (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component
                        {...props}
                        lmsData={lmsData}
                        imgbase={imgbase}
                        // className="animated fadeIn"
                      />
                    )}
                  />
                ) : null
              })}
            </ScrollToTop>
          </Switch>
        ) : (
          <div>Logging out...</div>
        )}
      </Box>
    )
  }
}

function ScrollToTop({ children }) {
  return (
    <>
      {window.scroll(0, 0)}
      {children}
    </>
  )
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}

export default withRouter(DefaultLayout)
