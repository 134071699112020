import { useMemo, useState, useEffect, useContext } from 'react'
import { UserContext } from '../../../App'
import {
  modulesNavHeader,
  reportsNav,
  adminNav,
  superNav,
  logoutNav,
} from '../../../_nav'
import * as actions from '../../../_actions'
import qs from 'query-string'

export function useNav(_isAdmin, groupID) {
  const { dispatch, state } = useContext(UserContext)
  const { prodList } = state
  const [navigation, setNavigation] = useState({ items: [] })
  try {
    // admin checks
    const isAdmin = _isAdmin === true
    const isSuper = groupID === 1

    // generate nav menu
    useMemo(() => {
      // build nav menu
      const productMenu = prodList
      const reportsMenu = reportsNav
      const logoutMenu = logoutNav
      const adminMenu = isAdmin ? adminNav : []
      const superMenu = isSuper ? superNav : []

      // set nav menu
      setNavigation({
        items: [
          ...modulesNavHeader,
          ...productMenu,
          ...reportsMenu,
          ...adminMenu,
          ...superMenu,
          ...logoutMenu,
        ],
      })
    }, [_isAdmin, prodList])
  } catch (error) {
    console.error(error)
    const type = actions.USER_MODULES
    const payload = { hasError: true, error: error.message }
    dispatch({ type, payload })
  }
  return { navigation }
}

export function useFetchModuleList() {
  const { dispatch, api, env } = useContext(UserContext)
  const { API_ENDPOINT_RPT } = env
  try {
    const fetchModuleList = async () => {
      const hasSessionStorage = sessionStorage.length > 0
      try {
        if (hasSessionStorage) {
          const { data } = await api.get(`${API_ENDPOINT_RPT}/moduleList`)
          dispatch({ type: actions.USER_MODULES, payload: data })
        }
      } catch ({ response }) {
        const { status, statusText, data } = response
        dispatch({
          type: actions.USER_UPDATE,
          payload: { hasError: true, error: { status, statusText, data } },
        })
      }
    }

    useEffect(() => {
      fetchModuleList()
    }, [])
  } catch (error) {
    console.error(error)
    dispatch({
      type: actions.USER_MODULES,
      payload: { hasError: true, error: error.message },
    })
  }
}

export function useParseJwtInQueryString(props) {
  useEffect(() => {
    const searchParams = props.location.search.replace('?', '')
    const queryString = qs.parse(searchParams)
    const hasJwt = !!queryString.jwt
    hasJwt && sessionStorage.setItem('jwt', queryString.jwt)
  }, [])
}
