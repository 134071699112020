import React, { Component } from 'react'
import { Flex, Box } from 'rebass'
import logo from '../../assets/img/brand/nyulangone-grey3.svg'

function DefaultFooter() {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      py={2}
      style={{
        color: '#23282c',
        background: '#f0f3f5',
        borderTop: '1px solid #c8ced3',
      }}
    >
      <Box mx={3}>
        <img src={logo} alt="logo" width="75px" />
      </Box>
      <Box mx={3}>
        <a href="http://wise-md.med.nyu.edu/">Wise-MD</a> &copy;{' '}
        {new Date().getFullYear()}
      </Box>
    </Flex>
  )
}

export default DefaultFooter
