import React, { useState, useContext, useEffect } from 'react'
import qs from 'query-string'
import styled from 'styled-components'
import { fontSize } from 'styled-system'
import { Redirect } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input as _Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import * as actions from '../../../_actions'
import logo from '../../../assets/img/brand/WISEMD_LOGO_UC23.svg'
import CourseClerkshipSelector from '../ClerkshipSelector/CourseClerkshipSelector'
import { UserContext } from '../../../App'

const Input = styled(_Input)`
  ${fontSize}
`

const LoginAqf = ({ history, location }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [msg, setMsg] = useState('Loading...')
  const [clerkshipPrompt, setClerkshipPrompt] = useState(false)
  const [course, setCourse] = useState('')

  const { state, dispatch, api } = useContext(UserContext)
  const stateMsg = {
    '1': 'Checking profile',
    '2': 'Updating profile',
    '3': 'Checking clerkships....',
    '4': 'Get course clerkhsip...',
    '5': 'Opening ...',
  }

  useEffect(() => {
    ssoLoginHandler()
  }, [])

  async function ssoLoginHandler() {
    const { jwt, userName, isAdmin, course } = qs.parse(location.search)
    setCourse(course)
    const ssoSession = { jwt, userName, isAdmin }
    const isSSO = jwt && userName
    isSSO && sessionStorage.setItem('session', JSON.stringify(ssoSession))
    isSSO && jwt && sessionStorage.setItem('jwt', jwt)

    if (isSSO) {
      dispatch({
        type: actions.USER_UPDATE,
        payload: { ...data, hasError: false, error: null, loginState: 1 },
      })
      setMsg('Checking profile....')
      const { data = {} } = await api.post(`/login/aqf`)

      dispatch({
        type: actions.USER_UPDATE,
        payload: { ...data, hasError: false, error: null, loginState: 3 },
      })
      setMsg('Checking clerkships....')

      setMsg(JSON.stringify(data))
      console.log(data)
      setClerkshipPrompt(data.clerkshipPrompt)

      if (data.clerkshipPrompt && !data.isAdmin) {
        dispatch({
          type: actions.USER_UPDATE,
          payload: { ...data, hasError: false, error: null, loginState: 4 },
        })
      } else {
        dispatch({
          type: actions.USER_UPDATE,
          payload: { ...data, hasError: false, error: null, loginState: 5 },
        })
      }
    }
    console.log(state)
    console.log(course)
  }

  return (
    <Form
      className="app flex-column justify-content-center align-items-center"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Container>
        {state.loginState > 3 &&
        state.jwt &&
        !state.clerkshipPrompt &&
        (state.clerkshipID || state.isAdmin) ? (
          <Redirect to="/module/" />
        ) : // PROMPT FOR CLERKSHIP IF NONE SELECTED
        state.loginState && state.loginState > 3 && state.clerkshipPrompt ? (
          <CourseClerkshipSelector
            clerkships={state.clerkships}
            course={course}
          />
        ) : null}
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <div>{stateMsg[state.loginState] || ''}</div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}

export default LoginAqf
