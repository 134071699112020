import { useState, useEffect } from 'react'

export function useSessionStorage() {
  // set up sessionStorage for userData persistence
  let [
    { userName, isAdmin, isSuperAdmin, jwt, isJwtExpired, groupID },
    setSessionData,
  ] = useState({})

  useEffect(() => {
    const sessionObj = sessionStorage.session || '{}'
    const session = JSON.parse(sessionObj)
    const { userName = '', isAdmin = false, jwt, groupID } = session
    setSessionData({ userName, isAdmin: JSON.parse(isAdmin), jwt, groupID })
  }, [userName, isAdmin, jwt, isJwtExpired])

  return { groupID, userName, isAdmin, isSuperAdmin, jwt, isJwtExpired }
}
