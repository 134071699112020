import React from 'react'
import styled from 'styled-components'
import { fontSize } from 'styled-system'
import { Redirect } from 'react-router'

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input as _Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap'
import logo from '../../../assets/img/brand/WISEMD_LOGO_UC23.svg'
import { usePwReset } from './hooks/usePasswordReset'

const Input = styled(_Input)`
  ${fontSize}
`

const PasswordReset = () => {
  const { handlePWReset, error, setPW1, setPW2, state, PW1, PW2 } = usePwReset()

  return state.passwordReset ? (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        handlePWReset()
      }}
      className="app flex-column justify-content-center align-items-center"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <img src={logo} alt="logo" width="144px" className="mb-3" />
                  <p className="text-muted">Reset your password</p>

                  {/* PASSWORD 1 */}
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      fontSize={2}
                      type="password"
                      placeholder="New Password"
                      value={PW1}
                      onChange={(e) => {
                        setPW1(e.target.value)
                      }}
                    />
                  </InputGroup>

                  {/* PASSWORD 2 */}
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      fontSize={2}
                      type="password"
                      placeholder="Confirm Password"
                      value={PW2}
                      onChange={(e) => {
                        setPW2(e.target.value)
                      }}
                    />
                  </InputGroup>
                  <Row>
                    <Col xs="12">
                      {error && <div className="text-danger mb-4">{error}</div>}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Button
                        type="submit"
                        color="warning"
                        className="px-4"
                        onClick={handlePWReset}
                      >
                        Reset Password
                      </Button>
                    </Col>
                    <Col xs="6" className="text-right"></Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </Form>
  ) : (
    <Redirect to="/login" />
  )
}

export default PasswordReset
