import React, { useContext, useEffect } from 'react'
import qs from 'query-string'
import styled from 'styled-components'
import { fontSize } from 'styled-system'
import { Redirect } from 'react-router'
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input as _Input,
  Row,
} from 'reactstrap'

import * as actions from '../../../_actions'
import logo from '../../../assets/img/brand/WISEMD_LOGO_UC23.svg'
import ClerkshipSelector from '../ClerkshipSelector/ClerkshipSelector'
import { UserContext } from '../../../App'

const Input = styled(_Input)`
  ${fontSize}
`

const SSO = {
  FAIL: -1,
  LOGIN: 1,
  VALIDATE: 2,
  VALID: 3,
}
const LoginSSO = ({ history, location }) => {
  function say(msg) {
    var str = msg
    if (isObject(msg)) {
      str = JSON.stringify(msg, null, 2)
    }
    console.log('LoginSSO: ' + str)
  }

  function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object
  }

  const { state, dispatch, api } = useContext(UserContext)

  say('ENTER')

  function getSSOLbl(code) {
    var lbl = ''
    switch (code) {
      case SSO.FAIL:
        lbl = 'Failed'
        break
      case SSO.LOGIN:
        lbl = 'Login'
        break
      case SSO.VALIDATE:
        lbl = 'Validate'
        break
      case SSO.VALID:
        lbl = 'Valid'
        break
      default:
        lbl = ''
        break
    }
    return lbl
  }

  const isSSO = location.pathname.startsWith('/login/sso') ? true : false

  // LIFECYCLE HOOKS
  useEffect(() => {
    say('componentDidMount')
    dispatch({
      type: actions.USER_UPDATE,
      payload: { ssoState: SSO.LOGIN || null },
    })

    return () => {
      say('componentWillUnmount')
    }
  }, [])

  useEffect(() => {
    say('LOCATION IS: ' + location.pathname)
  }, [location])

  useEffect(() => {
    if (isSSO) {
      say('In SSO Handler... ')
      const { jwt, userName, isAdmin } = qs.parse(location.search)
      processSSO(jwt, userName, isAdmin)
        .then(res => {
          say(res)
          if (res.valid) {
            let data = res.rtn.data

            sessionStorage.setItem('session', JSON.stringify(data))
            sessionStorage.setItem('jwt', data.jwt)

            dispatch({
              type: actions.USER_UPD_SESS_STORE,
              payload: { session: JSON.stringify(data), jwt: data.jwt },
            })

            dispatch({
              type: actions.USER_UPDATE,
              payload: {
                ...data,
                // asError: false,
                hasError: false,
                error: null,
                nm: 'LOGIN**SSO',
                ssoState: SSO.VALID,
              },
            })

            say('goto module')
            history.replace('/login')
          } else {
            say('goto login')
            dispatch({
              type: actions.USER_UPDATE,
              payload: { ssoState: SSO.FAIL, statusMsg: 'SSO Failed' },
            })
            history.replace('/login')
          }
          say('SSO done')
        })
        .catch(e => {
          say('ERR')
          console.log(e)
          say(e.toString())
          state.statusMsg = 'SSO Failed'
          history.replace('/login')
        })
    }
  }, [state.ssoState])

  async function processSSO(jwt, userName, isAdmin) {
    dispatch({ type: actions.USER_CLEARALL })

    say('*** SSO AS ' + userName)
    var obj = await validateSSO(jwt, userName, isAdmin)
    say(obj)
    return obj
  }

  async function validateSSO(jwt, userName, isAdmin) {
    var rtn = { valid: false, rtn: {} }
    try {
      // check to see if jwt valid and matches username
      const resp = await api.post(`validateSSO`, {
        jwt: jwt,
        username: userName,
        isAdmin: isAdmin,
      })

      rtn.rtn = resp
      rtn.valid = true
      return rtn
    } catch (E) {
      console.log(E)
      rtn.valid = false
      return rtn
    }
  }

  return say('RENDER - SSO STATE : ' + getSSOLbl(state.ssoState)) || 1 ? (
    <Form
      className="app flex-column justify-content-center align-items-center"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Container>
        {state.jwt && !state.isAdmin && !state.clerkshipID ? (
          <ClerkshipSelector clerkships={state.clerkships} />
        ) : null}
        {state.jwt && (state.clerkshipID || state.isAdmin) ? (
          <Redirect to="/module/surgery" />
        ) : null}
        <Row className="justify-content-center">
          <Col md="5">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <img src={logo} alt="logo" width="144px" className="mb-3" />
                  <div>Checking Credentials: {getSSOLbl(state.ssoState)}</div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </Form>
  ) : null
}

export default LoginSSO
