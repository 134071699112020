import React from 'react'
import { Flex, Box, Text } from 'rebass'
import Icon from '@mdi/react'
import { mdiAccountCircle } from '@mdi/js'
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap'
import { useMedia } from 'react-use-media'
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from '@coreui/react'
import logo from '../../assets/img/brand/WISEMD_LOGO_UC23.svg'
import sygnet from '../../assets/img/brand/sygnet.svg'

const DefaultHeader = ({ userName, isAdmin }) => {
  const showUsername = useMedia({ minWidth: '40em' })
  const showMobileLogo = useMedia({ maxWidth: '62em' })

  return (
    <>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <Flex alignItems="center">
        <AppNavbarBrand
          style={
            showMobileLogo ? { marginTop: '3px' } : { margin: '0 0 0 00px' }
          }
          full={{
            src: logo,
            height: '28px',
            alt: 'Wise-MD',
          }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
      </Flex>
      <AppSidebarToggler className="d-md-down-none" display="lg" />
      <Nav className="ml-auto" navbar>
        <AppHeaderDropdown direction="down">
          {/* <DropdownToggle nav> */}
          <Flex alignItems="center">
            <Text fontSize={0} mr={1}>
              {showUsername ? userName : null}
            </Text>
            <Box mr={[3]}>
              <Icon
                path={mdiAccountCircle}
                size={'2.15em'}
                color={isAdmin ? 'var(--warning)' : 'var(--gray)'}
              />
            </Box>
          </Flex>
          {/* </DropdownToggle> */}
          {/* <DropdownMenu right style={{ right: 'auto' }}>
            {showUsername ? null : (
              <DropdownItem header>{userName}</DropdownItem>
            )}
            <Link to="/logout">
              <DropdownItem style={{ cursor: 'pointer' }}>
                <i className="fa fa-lock" />
                Logout
              </DropdownItem>
            </Link>
          </DropdownMenu> */}
        </AppHeaderDropdown>
      </Nav>
    </>
  )
}

export default DefaultHeader
