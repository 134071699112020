import React from 'react'
import { Card as _Card } from 'rebass'
import { height } from 'styled-system'
import styled from 'styled-components'

const StyledCard = styled(_Card)`
  ${height}
`

interface CardProps {
  children: any
  className?: string
  borderRadius?: number
  props?: any
  height?: any
}

const Card = ({
  children,
  className,
  borderRadius = 4,
  height = '100%',
  ...props
}: CardProps) => {
  return (
    <StyledCard
      mb={[2, 3]}
      className={`border ${className} shadow-sm`}
      borderRadius={borderRadius}
      height={height}
      {...props}
    >
      {children}
    </StyledCard>
  )
}

export default Card
