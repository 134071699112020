import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'

// Google Analytics
ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID)

const history = createBrowserHistory()

export function initGoogleAnalytics() {
  history.listen((location) => {
    const currentPage = `${location.pathname}${location.hash}`
    ReactGA.set({ page: currentPage })
    ReactGA.pageview(currentPage)
  })
}
