export default {
  colors: {
    text: '#000',
    background: '#fff',
    primary: 'var(--primary)',
    secondary: 'var(--secondary)',
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 700,
      fontSize: [4, 5],
    },
  },
  cards: {
    primary: {
      borderRadius: 4,
      width: '100%',
      border: '1px solid',
      borderColor: 'secondary',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
}
