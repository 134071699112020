import React from 'react'
import { useQuery, useMutation, queryCache } from 'react-query'
import { UserContext } from '../App'

export const useInstitution = (dispatch, state) => {
  const { api, env } = React.useContext(UserContext)
  const { API_ENDPOINT_RPT } = env
  const [hashMap, setHashMap] = React.useState(null)

  const institution = {
    domainID: state.currentInstitution?.domainID,
  }

  const handleOnChange = React.useCallback(
    (e = {}) => {
      dispatch({ type: 'SET_CLERKSHIP', payload: {} })
      dispatch({ type: 'SET_INSTITUTION', payload: e })
      dispatch({ type: 'SET_IS_ADDING_NEW_INSTITUTION', payload: false })
      dispatch({ type: 'SET_IS_ADDING_NEW_USER', payload: false })
      dispatch({ type: 'SET_IS_ADDING_NEW_USER_CLERKSHIP', payload: false })
      dispatch({ type: 'SET_IS_ADDING_NEW_CLERKSHIP', payload: false })
    },
    [institution.domainID]
  )

  const handleUpdateInstitution = React.useCallback((row) => {
    dispatch({ type: 'SET_IS_UPDATING_INSTITUTION', payload: true })
    dispatch({
      type: 'SET_INSTITUTION_DATA',
      payload: { institution: row.original.domain },
    })
  }, [])

  const handleAddNewInstitution = React.useCallback(() => {
    dispatch({ type: 'SET_IS_ADDING_NEW_INSTITUTION', payload: true })
    dispatch({
      type: 'SET_INSTITUTION',
      payload: {
        domainID: null,
        courses: {
          ...state.defaultCourses,
          wmd: {
            name: 'Surgery + Skills',
            enabled: true,
          },
        },
        externalSource: 'NONE',
        externalSourceId: null,
        institution: null,
      },
    })
    dispatch({ type: 'SET_CLERKSHIPS', payload: [] })
    dispatch({ type: 'SET_CLERKSHIPS', payload: [] })
    dispatch({ type: 'SET_INSTITUTION_EXTERNAL_SOURCES', payload: [] })
    dispatch({ type: 'SET_CURRENT_EXTERNAL_SOURCE', payload: null })
    dispatch({ type: 'SET_UPDATED_EXTERNAL_SOURCE', payload: null })
  }, [])

  const isAddInstitutionDisabled = React.useMemo(() => {
    const courses = state.currentInstitution?.courses

    return (
      courses &&
      Object.values(courses)
        .map((course) => course.enabled)
        .filter(Boolean).length < 2
    )
  }, [state.currentInstitution?.courses])

  const handleEditInstitution = React.useCallback(() => {
    dispatch({ type: 'SET_IS_ADDING_NEW_INSTITUTION', payload: false })
    dispatch({
      type: 'SET_INSTITUTION_DATA',
      payload: {
        domainID: state.currentInstitution.domainID,
        institution: state.currentInstitution.domain,
        domain: state.currentInstitution.domain,
      },
    })

    dispatch({
      type: 'SET_CURRENT_EXTERNAL_SOURCE',
      payload: {
        externalSource: state.currentExternalSource.externalSource,
        externalSourceId: state.currentExternalSource.externalSourceId,
      },
    })
    dispatch({
      type: 'SET_UPDATED_EXTERNAL_SOURCE_DATA',
      payload: {
        externalSource: state.updatedExternalSource.externalSource,
        externalSourceId: state.updatedExternalSource.externalSourceId,
      },
    })
    dispatch({ type: 'SET_CLERKSHIPS', payload: [] })
  }, [])

  const handleCancelUpdateInstitution = React.useCallback(() => {
    dispatch({ type: 'SET_IS_UPDATING_INSTITUTION', payload: false })
  }, [])

  const handleCancelNewInstitution = React.useCallback(() => {
    dispatch({ type: 'SET_IS_ADDING_NEW_INSTITUTION', payload: false })
    dispatch({ type: 'SET_INSTITUTION', payload: null })
    dispatch({ type: 'SET_CLERKSHIPS', payload: [] })
    dispatch({ type: 'SET_INSTITUTION_EXTERNAL_SOURCES', payload: [] })
    dispatch({ type: 'SET_CURRENT_EXTERNAL_SOURCE', payload: null })
    dispatch({ type: 'SET_UPDATED_EXTERNAL_SOURCE', payload: null })
  }, [])

  const handleInstitutionData = React.useCallback(({ field, value }) => {
    dispatch({ type: 'SET_INSTITUTION_DATA', payload: { [field]: value } })
  }, [])

  const handleInstitutionCourse = React.useCallback(({ field, value }) => {
    dispatch({
      type: 'SET_INSTITUTION_COURSE',
      payload: { course: field, value },
    })
  }, [])

  const query = useQuery(
    'institutions',
    async () => {
      const { data } = await api.get(`${API_ENDPOINT_RPT}/domainList`)
      return data
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onSettled: (data) => {
        const institutionMap = data.reduce((acc, curr) => {
          acc[curr.domainID] = curr
          return acc
        }, {})

        setHashMap(institutionMap)
      },
    }
  )

  const [create, createInfo] = useMutation(
    async () => {
      const url = `${env.API_ENDPOINT_GW}/admin/addDomainExtended`
      const payload = state.currentInstitution
      const { data } = await api.post(url, payload)
      return data
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('institutions')
        queryCache.invalidateQueries(['institution', institution.domainID])
      },
      onSettled: ([data]) => {
        dispatch({ type: 'SET_IS_UPDATING_USER', payload: false })

        setTimeout(() => {
          dispatch({ type: 'SET_IS_ADDING_NEW_INSTITUTION', payload: false })
          dispatch({
            type: 'SET_INSTITUTION',
            payload: {
              domain: data.institution,
              domainID: data.domainID,
            },
          })
        }, 250)
      },
    }
  )

  const [update, updateInfo] = useMutation(
    async () => {
      const url = `${env.API_ENDPOINT_GW}/admin/updDomainName`
      const payload = {
        domainName: state.currentInstitution?.institution,
        domainID: state.currentInstitution?.domainID,
      }

      const { data } = await api.put(url, payload)
      return data
    },
    {
      onSettled: () => {
        queryCache.invalidateQueries(['institutions'])
        queryCache.invalidateQueries(['institution', institution.domainID])
      },
    }
  )

  const columns = React.useMemo(() => {
    return [
      { Header: 'Name', accessor: 'domain' },
      { Header: 'ID', accessor: 'domainID' },
      {
        Header: 'Modify',
        id: 'modify',
        Cell: ({ row }) => (
          <a
            href="#"
            title="Edit"
            onClick={(e) => {
              e.preventDefault()
              handleUpdateInstitution(row)
              handleEditInstitution(state)
            }}
          >
            Edit
          </a>
        ),
      },
    ]
  }, [])

  return {
    isAddInstitutionDisabled,
    handleOnChange,
    handleAddNewInstitution,
    handleCancelNewInstitution,
    handleCancelUpdateInstitution,
    handleUpdateInstitution,
    handleInstitutionData,
    handleInstitutionCourse,
    handleEditInstitution,
    columns,
    hashMap,
    query,
    create,
    createInfo,
    update,
    updateInfo,
  }
}
