//@ts-check
require('dotenv')
import React, { useReducer } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import { initGoogleAnalytics } from './_analytics'
import './App.scss'

// React Components
import DefaultLayout from './containers/DefaultLayout/DefaultLayout'
import {
  Login,
  LoginSSO,
  Page404,
  Page500,
  Register,
  LoginAqf,
} from './views/Pages'
import ClerkshipSelector from './views/Pages/ClerkshipSelector/ClerkshipSelector'
import CourseClerkshipSelector from './views/Pages/ClerkshipSelector/CourseClerkshipSelector'
import PasswordReset from './views/Pages/PasswordReset/PasswordReset'

// React state & context & hooks
import { reducer, initialState } from './_state'
import { useSessionStorage } from './hooks/useSessionStorage'
import { useAxios } from './hooks/useAxios'
export const UserContext = React.createContext(null)

const isProduction = process.env.NODE_ENV === 'production'
const env = {}
env.AUTH_CHECK_INTERVAL_LMS = process.env.AUTH_CHECK_INTERVAL_LMS
env.DOCKER_HTTP_EXTERNAL_PORT = process.env.DOCKER_HTTP_EXTERNAL_PORT
env.PROXY_ROUTE_RPT = process.env.PROXY_ROUTE_RPT || 'rpt'
env.PROXY_ROUTE_REPORTING = process.env.PROXY_ROUTE_REPORTING || 'reporting'
env.PROXY_ROUTE_GW = process.env.PROXY_ROUTE_GW || 'gw'
env.PROXY_BASE_HOST = process.env.PROXY_BASE_HOST
env.PROXY_ROUTE_VERSION = process.env.PROXY_ROUTE_VERSION
env.PROXY_BASE_PROTO = process.env.PROXY_BASE_PROTO
env.IMGBASE = process.env.IMGBASE

env.API_ENDPOINT_GW = isProduction
  ? `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_GW}`
  : `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}:${env.DOCKER_HTTP_EXTERNAL_PORT}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_GW}`

env.API_ENDPOINT_RPT = isProduction
  ? `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_RPT}`
  : `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}:${env.DOCKER_HTTP_EXTERNAL_PORT}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_RPT}`

env.API_ENDPOINT_REPORTING = isProduction
  ? `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_REPORTING}`
  : `${env.PROXY_BASE_PROTO}://${env.PROXY_BASE_HOST}:${env.DOCKER_HTTP_EXTERNAL_PORT}/v${env.PROXY_ROUTE_VERSION}/${env.PROXY_ROUTE_REPORTING}`

initGoogleAnalytics()

export default function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { jwt } = useSessionStorage()
  const request = { state, jwt, API_ENDPOINT_RPT: env.API_ENDPOINT_RPT }
  const { api } = useAxios(request)
  React.useEffect(
    () => console.log('GIT_VERSION:', process.env.GIT_VERSION),
    []
  )

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        api,
        jwt,
        env,
        API_ENDPOINT_GW: env.API_ENDPOINT_GW,
      }}
    >
      <HashRouter>
        <Switch>
          <Route exact path="/">
            {jwt ? <DefaultLayout /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route
            exact
            path="/passwordReset"
            name="Password Reset"
            component={PasswordReset}
          />
          <Route
            exact
            path="/login/sso"
            name="Login SSO Page"
            component={LoginSSO}
          />

          <Route
            exact
            path="/login/aqf"
            name="Login AQF Page"
            component={LoginAqf}
          />

          <Route
            exact
            path="/clerkship"
            name="Clerkship"
            component={ClerkshipSelector}
          />

          <Route
            exact
            path="/courseclerkship"
            name="Course Clerkship"
            component={CourseClerkshipSelector}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            component={Register}
          />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />

          <Route exact path="/logout" name="Home" component={DefaultLayout} />
          <Route
            exact
            path="/session_expired"
            name="Home"
            component={DefaultLayout}
          />

          <Route
            exact
            path="/module/:collection/"
            name="Module"
            component={DefaultLayout}
          />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    </UserContext.Provider>
  )
}
