import React from 'react'
import { Box } from 'rebass'
import styled from 'styled-components'
import { height } from 'styled-system'

const StyledCardBody = styled(Box)`
  ${height}
`

const CardBody = ({ children, ...props }) => {
  return (
    <StyledCardBody m={3} p={2} {...props}>
      {children}
    </StyledCardBody>
  )
}

export default CardBody
